<template>

  <b-overlay :show="show" rounded="sm">
    <div class="d-block text-center">
      <b-table-lite hover
                    :fields="fields"
                    :items="supplierProducts"
                    :tbody-tr-class="rowClass"
                    size="sm"
      >
        <template #cell(iskonto)="data">
             <span class="text-nowrap" v-if="data.item.price && data.item.market_price">
               % {{ (((data.item.market_price - data.item.price) / data.item.market_price) * 100).toFixed(1) }}
            </span>
          <span class="text-nowrap" v-else>
             -
            </span>
        </template>
        <template #cell(price)="data">
            <span class="text-nowrap">
              {{ data.item.currency ? data.item.currency : 'TL' }} {{ data.item.price }}
            </span>
        </template>

        <template #cell(actions)="data">
          <b-button size="sm" variant="outline-primary" @click="matching(data.item)">Eşitle</b-button>
        </template>
      </b-table-lite>
      <hr>
      <table>
        <tr>
          <td>PSF (TL)</td>
          <td>İsk (%)</td>
          <td>Kdv (%)</td>
          <td>KDV'siz F. (TL)</td>
          <td>Kdv'li F. (TL)</td>
          <td>KDV'li A.F. (TL)</td>
          <td>İşlem</td>
        </tr>
        <tbody>
        <tr>
          <td>
            <b-input v-model="data2.market_price" @blur="marketpriceHesapla" :disabled="!data2.isVat"/>
          </td>
          <td>
            <b-input v-model="data2.iskonto" @blur="iskontoHesap" :disabled="!data2.isVat"/>
          </td>
          <td>
            <v-select v-model="data2.vat"
                      label="title" :options="[0,1,8,18]"
                      @option:selected="kdvHesap"
                      :disabled="data2.isVat"/>
          </td>
          <td>
            <b-input @blur="alisHesap2" v-model="data2.pricekdv" :disabled="data2.isVat"/>
          </td>

          <td>
            <b-form-checkbox v-model="data2.isVat" name="check-button" switch inline @change="yenidenKdv"/>
          </td>
          <td>
            <b-input v-model="data2.price" @blur="alisHesap" :disabled="!data2.isVat"/>
          </td>
          <td>
            <b-button :disabled="!data2.price" size="sm" variant="primary" @click="saveSupplier">Kaydet</b-button>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </b-overlay>
</template>
<script>
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from "@core/utils/validations/validations";

export default {
  name: "StockConfirmSupplierProductList",
  components: {vSelect},
  props: {
    supplierProducts: {type: Array, required: true},
    product: {type: Object, required: true}
  },
  data: () => ({
    show: false,
    data2: {
      market_price: null,
      iskonto: null,
      price: null,
      pricekdv: null,
      vat: 0,
      isVat: true
    },
    data: {
      supplier: {},
    },
    fields: [
      {key: 'supplier.name', label: 'TEDARİKÇİ'},
      {key: 'stock', label: 'Stok'},
      {key: 'market_price', label: 'PSF (TL)'},
      {key: 'iskonto', label: 'İSKONTO (%)'},
      {key: 'price', label: 'ALIŞ (TL)'},
      {key: 'actions', label: 'YÖNET'},
    ]
  }),
  methods: {
    rowClass(item) {
      if (item.supplier_id == this.data.supplier.id) return 'table-success'
      return ''
    },
    marketpriceHesapla($event) {
      this.data2.price = ($event.target.value - ($event.target.value * this.data2.iskonto / 100)).toFixed(2)
    },
    iskontoHesap($event) {
      this.data2.price = (this.data2.market_price - (this.data2.market_price * $event.target.value / 100)).toFixed(2)
    },
    alisHesap($event) {
      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - $event.target.value) / this.data2.market_price) * 100).toFixed(2);
    },
    alisHesap2($event) {
      this.data2.price = (parseFloat($event.target.value) + ((parseFloat($event.target.value) * parseInt(this.data2.vat)) / 100)).toFixed(2)

      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - this.data2.price) / this.data2.market_price) * 100).toFixed(2);
    },
    yenidenKdv() {
      this.data2.price = null
    },
    kdvHesap(selectedOption) {
      if (this.data2.pricekdv > 0)
        this.data2.price = (parseFloat(this.data2.pricekdv) + ((parseFloat(this.data2.pricekdv) * parseInt(selectedOption)) / 100)).toFixed(2)

      if (this.data2.market_price > 0)
        this.data2.iskonto = (((this.data2.market_price - this.data2.price) / this.data2.market_price) * 100).toFixed(2);
    },
    matching(item) {
      this.data2.market_price = item.market_price;
      if (item.market_price > 0)
        this.data2.iskonto = (((item.market_price - item.price) / item.market_price) * 100).toFixed(1);
      this.data2.price = item.price;
    },
    saveSupplier() {
      if (!(this.data2.market_price > 0 && this.data2.price > 0)) {
        alert('Veriler eksik veya yanlış')
        return;
      }

      let data = {
        id: this.product.id,
        stockCard: {
          market_price: this.data2.market_price,
          discount: (((this.data2.market_price - this.data2.price) / this.data2.market_price) * 100).toFixed(2),
          price: this.data2.price,
          status: 2
        }
      }
      this.$store.dispatch('stockComfirm/editStock', data)
    }
  },
}
</script>

<style scoped>
.table th, .table td {
  padding: 0rem !important;
}
</style>
