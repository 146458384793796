<template>
  <b-row>

    <b-col cols="12">
      <b-table
          striped
          hover
          responsive
          :items="_stocks"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
      >

        <template #cell(supplier)="data">
          Sıra No: <b>{{ data.item.id }}</b><br>
          <b-badge variant="success">{{ supplierName(data.item.supplier_id) }}</b-badge>
          <br>
          <b-badge> {{ supplierName(data.item.stock_return) }}</b-badge>
          <hr>
          <b-button variant="danger" size="sm" v-if="isAdmin() && data.item.status==0 && data.item.sub_items.length<1"
                    @click="deleteSockCard(data.item.id)">
            <feather-icon icon="Trash2Icon"></feather-icon>
            Sil
          </b-button>
        </template>

        <template #cell(status)="data">

          <b-badge variant="info">{{ matchStatusName(data.item.status) }}</b-badge>
          <br>
          Ekleyen: {{ userName(data.item.add_user_id) }} {{ data.item.add_user_id }}<br>
          Onay.: {{ userName(data.item.approver_user_id) }}

          <hr>
          <span style="font-size: 11px"><ViewDate :date="data.item.created_at"></ViewDate></span>
          <span style="font-size: 11px"><ViewDate :date="data.item.updated_at"></ViewDate></span>
          <hr>
          <div v-for="(orderProduct,opIndex) in data.item.order_products" :key="opIndex">
            <b-badge variant="danger"> Sipariş: {{ orderProduct.count }}</b-badge>
            <b-badge variant="danger"> Adet: {{ orderProduct.sum }}</b-badge>
          </div>
        </template>

        <template #cell(image)="data">
          <ProductImages
              v-if="data.item.product"
              prefix="https://api.kitapfirsatlari.com/"
              :images="data.item.product.images"/>
        </template>

        <template #cell(product)="data">
          <b-badge>{{ data.item.search_barcode }}</b-badge>
          <br>
          <b-badge variant="dark" v-if="data.item.product">{{ data.item.product.barcode }}</b-badge>
          <br>
          <b-badge v-if="data.item.product">{{ data.item.product.id }}</b-badge>
          <br>
          <span class="bg-info" v-if="data.item.product">{{ data.item.product.name }}</span>
          <br>
          <b-badge v-if="data.item.product && data.item.product.brand">{{ data.item.product.brand.name }}</b-badge>
          <br>
          <b-badge variant="danger" v-if="data.item.sub_items.length>0">{{ data.item.sub_items.length }}</b-badge>
          <hr>
          <OrderProductSupplierProduct :product="data.item.product" :productAccounting="{}"/>
          <OrderProductStock :order="{}" :product="data.item.product" :stocks2="true"/>
        </template>


        <template #cell(detail)="data">
          <b-row>
            <StockComfirmSupplierProductList
                :product="data.item"
                :supplierProducts="data.item.product.supplier_products"/>
          </b-row>
        </template>


      </b-table>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";
import ProductImages from "@/components/ProductImages";
import SupplierProduct from "@/views/product/supplierProduct";
import authMixins from "@/mixins/authMixins";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ViewDate from "@/views/common/ViewDate";
import {BBadge} from "bootstrap-vue";
import OrderProductSupplierProduct from "@/views/order/list/OrderProductSupplierProduct.vue";
import StockComfirmSupplierProductList from "@/views/stock/confirm/StockComfirmSupplierProductList.vue"
import OrderProductStock from "@/views/order/list/OrderProductStock.vue";

export default {
  mixins: [authMixins],
  components: {
    OrderProductStock,
    OrderProductSupplierProduct,
    BBadge,
    ProductImages,
    SupplierProduct,
    ViewDate,
    StockComfirmSupplierProductList
  },
  data() {
    return {
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      fields: [
        {key: 'supplier', label: 'Tedarikçi'},
        {key: 'status', label: 'Durum'},
        {key: 'shelve_name', label: 'Raf Adı', sortable: true},
        {key: 'product', label: 'Ürün Bilgisi', thStyle: {'width': '350px'}},
        {key: 'image', label: 'Resim'},
        {key: 'detail', label: 'Detay', thStyle: {'width': '650px'}},
      ]
    }
  },
  computed: {
    ...mapGetters('matchStatus', ["_matchStatus"]),
    ...mapGetters("stockComfirm", ["_stocks"]),
    ...mapGetters('users', ['_users']),
    ...mapGetters('supplier', ['_suppliers']),
  },
  methods: {
    supplierName(id) {
      let supplier = this._suppliers.find(supplier => supplier.id == id);
      if (!supplier) return ''
      return supplier.name
    },
    userName(id) {
      let user = this._users.find(user => user.id == id);
      if (!user) return '';
      return user.name;
    },
    matchStatusName(id) {
      let status = this._matchStatus.find(status => status.id == id);
      if (!status) return '';
      return status.status;
    },
    saveStock(stock) {
      if (!(stock.quantity > 0 && stock.price > 0 && stock.market_price > 0)) {
        alert('Veriler eksik veya yanlış')
        return;
      }
      let data = {
        id: stock.id,
        stockCard: {
          market_price: stock.market_price,
          discount: stock.discount,
          price: stock.price,
          status: 2
        }
      }
      this.$store.dispatch('stockComfirm/editStock', data)
    },
    deleteSockCard(id) {
      this.$store.dispatch('stockComfirm/deleteStockCard', id)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: res,
            })
          })
    }
  },
}
</script>

